import React from 'react'
import { useState } from 'react'

type StartType = {
    startGame: () => void;
    team1Ref: React.RefObject<HTMLInputElement>
    team2Ref: React.RefObject<HTMLInputElement>
}

export default function Start({startGame, team1Ref, team2Ref}: StartType){
    
    return (
        <div className='w-75 mx-auto h-100 d-flex justify-content-center align-items-center flex-column gap-3'>
            <h1>FAMILIADA</h1>
            <input ref={team1Ref} className='px-2' placeholder='Nazwa druzyny 1'></input>
            <input ref={team2Ref} className='px-2' placeholder='Nazwa druzyny 2'></input>
            <button className='px-5 py-2' onClick={startGame}>START</button>
        </div>
    )
}