import { useEffect, useState } from 'react';
import './App.css';
import questions, { QuestionType } from './questions';
import ButtonPoints from './components/ButtonPoints';
import Start from './components/Start';
import { useRef } from 'react';
import AddPointsButtons from './components/AddPointsButtons';






function App() {
  const answersSheet: string[][] = questions.map(question => {
    return question.odpowiedzi.map(odpowiedz => {
      return "_______________________________________"
    })
  })
  const wrongSound = require('./wrong.mp3')
  const [start, setStart] = useState(false)
  const [questionNumber, setQuestionNumber] = useState(0)
  const [questionsList, setQuestionsList] = useState<QuestionType[]>(questions);
  const [answers, setAnswers] = useState(answersSheet)
  const [teaminfo, setTeamInfo] = useState({
    team1Name: '',
    team2Name: '',
    team1Points: 0,
    team2Points: 0
  })
  const [pula, setPula] = useState(0)
  const team1Ref = useRef<HTMLInputElement | null>(null)
  const team2Ref = useRef<HTMLInputElement | null>(null)

  function handleKeyPress(event: KeyboardEvent){
    if (event.key === 'a') {
      const audio = new Audio(wrongSound)
      audio.play()
    }
  };

  useEffect(() => {
    if(start){
      window.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [start]);

  function nextQuestionHandler(){
    setPula(0)
    setQuestionNumber(prev => prev + 1)
  }

  function showAnswer(index: number){
    let newAnswers = answers.map((answer, ansIndex) => {
      if(ansIndex == questionNumber){
        return answer.map((oneAns, oneAnsIndex) => {
          return oneAnsIndex == index ? questionsList[questionNumber].odpowiedzi[oneAnsIndex] : oneAns
        })
      }else{
        return answer
      }
    })


    setAnswers(newAnswers)
    setPula(prev => prev + questions[questionNumber].points[index] * questions[questionNumber].mnoznik)
  }

  function setTeamPoints(teamNumber: number){
    if(teamNumber == 1){
      setTeamInfo(prev => {
        return {
          ...prev,
          team1Points: prev.team1Points + pula
        }
      })
    }else{
      setTeamInfo(prev => {
        return {
          ...prev,
          team2Points: prev.team2Points + pula
        }
      })
    }
    setPula(0)
  }

  function startGame(){
    const team1Value = team1Ref.current?.value || ''; 
    const team2Value = team2Ref.current?.value || '';
    setStart(true)
    setTeamInfo(prev => {
      return {
        ...prev,
        team1Name: team1Value,
        team2Name: team2Value
      }
    })
  }
  return (
    <div className="App d-flex justify-content-start align-items-ceneter flex-column">
      {
        start ? 
        <>
        <div className='w-75 d-flex justify-content-center align-items-center mt-5 mx-auto'>
          <div className='w-75 gap-2 d-flex justify-content-center align-items-end flex-column'>
            {questionsList[questionNumber].odpowiedzi.map((odpowiedz, index) => {
              return (
                <p className='me-3'>{index +1}.</p>
              )
            })}
          </div>
          <div className='w-75 gap-2 d-flex justify-content-center align-items-center flex-column'>
            {answers[questionNumber].map(answer => {
              return (
                <p className='text-upper'>{answer}</p>
              )
            })}
          </div>
          <div className='w-75 gap-3 d-flex justify-content-center align-items-center flex-column'>
            {questionsList[questionNumber].points.map((points, index) => {
                return (
                  <ButtonPoints key={`${questionNumber},${index}`} points={points} index={index} showAnswer={showAnswer}/>
                )
              })}
          </div>
        </div>
        <AddPointsButtons 
          questions={questions} 
          questionNumber={questionNumber} 
          setTeamPoints={setTeamPoints} 
          teamInfo={teaminfo} 
          setQuestionNumber={setQuestionNumber} 
          pula={pula}
          nextQuestionHandler={nextQuestionHandler}
        />
        </>
        :
        <Start startGame={startGame} team1Ref={team1Ref} team2Ref={team2Ref}/>
      }
    </div>
  );
}

export default App;
