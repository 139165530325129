import React from "react"

export type QuestionType = {
    pytanie: string,
    mnoznik: number,
    odpowiedzi: string[],
    points: number[]
}

let questions: QuestionType[]

export default questions = [
    {
        pytanie: 'Drzewo z szyszkami',
        mnoznik: 1,
        odpowiedzi: ['Sosna', 'Świerk', 'Jodła', 'Modrzew', 'Olcha', 'Cedr'],
        points: [28, 23, 17, 12, 9, 7]
    },
    {
        pytanie: 'Zdrobnienie imienia męskiego kończące się na REK',
        mnoznik: 1,
        odpowiedzi: ['Mirek', 'Jurek', 'Arek', 'Jarek', 'Czarek', 'Darek'],
        points: [25, 21, 17, 14, 11, 9]
    },
    {
        pytanie: 'Lektura którą pamiętasz ze szkoły',
        mnoznik: 1,
        odpowiedzi: ['Pan Tadeusz', 'Dziady', 'Krzyżacy', 'Lalka', 'Wesele', 'Chłopi'],
        points: [27, 20, 14, 13, 11, 8]
    },
    {
        pytanie: 'Co zobaczysz w gablocie na szkolnym korytarzu',
        mnoznik: 1,
        odpowiedzi: ['Medale', 'Zdjęcia', 'Puchary', 'Ogłoszenia', 'Plan lekcji', 'Rysunki'],
        points: [22, 19, 16, 14, 13, 10]
    }, 
    {
        pytanie: 'W jakiej postaci przyjmujemy leki',
        mnoznik: 2,
        odpowiedzi: ['Tabletki', 'Syrop', 'Maść', 'Zastrzyk', 'Krople'],
        points: [34, 24, 14, 12, 10]
    },  
    {
        pytanie: 'Czego można dotrzymać',
        mnoznik: 2,
        odpowiedzi: ['Słowa', 'Kroku', 'Umowy', 'Towarzystwa', 'Obietnicy'],
        points: [33, 21, 17, 12, 10]
    }, 
    {
        pytanie: 'Co robią dzieci gdy pada śnieg',
        mnoznik: 3,
        odpowiedzi: ['Lepią bałwana', 'Idą na sanki', 'Rzucają śnieżkami'],
        points: [40, 31, 23]
    },
    {
        pytanie: 'Roślina z której robi się tkaninę',
        mnoznik: 3,
        odpowiedzi: ['Bawełna', 'Len', 'Bambus'],
        points: [48, 33, 12]
    },  
]