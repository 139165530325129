
import {type QuestionType} from '../questions'

type AddPointsButtonsType = {
    questionNumber: number;
    questions: QuestionType[];
    setTeamPoints: (teamNumber: number) => void;
    setQuestionNumber: React.Dispatch<React.SetStateAction<number>>;
    teamInfo: {
        team1Name: string;
        team2Name: string;
        team1Points: number;
        team2Points: number;
    };
    pula: number;
    nextQuestionHandler: () => void;
}

export default function AddPointsButtons({questionNumber, setTeamPoints, setQuestionNumber, nextQuestionHandler, questions, teamInfo, pula}: AddPointsButtonsType){

    const {team1Name, team2Name, team1Points, team2Points} = teamInfo;
    

    return (
        <>
        <div className='d-flex justify-content-center gap-5 align-items-center mt-5'>
          <div className='d-flex justify-content-center align-items-center flex-column'><p>{team1Name}</p><p>{team1Points}</p></div>
          <div className='d-flex justify-content-center align-items-center flex-column'><p>{`Pula X${questions[questionNumber].mnoznik}`}</p><p>{pula}</p></div>
          <div className='d-flex justify-content-center align-items-center flex-column'><p>{team2Name}</p><p>{team2Points}</p></div>
        </div>
        <div className='d-flex w-50 mx-auto justify-content-center align-items-center'>
          <button className='mx-auto' onClick={() => setTeamPoints(1)}>{team1Name}</button>
            {questionNumber < questions.length -1 && <button className='mx-auto' onClick={nextQuestionHandler}>Nastepne pytanie</button>}
          <button className='mx-auto' onClick={() => setTeamPoints(2)}>{team2Name}</button>
        </div>
        </>
    )
}