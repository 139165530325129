import React from 'react'
import { useState } from 'react'

type ButtonPointsType = {
    points: number,
    showAnswer: (index: number) => void
    index: number,
}

export default function ButtonPoints({points, showAnswer, index}: ButtonPointsType){
    const [isClicked, setIsClicked] = useState(false)
    const correctSound = require("../correct.mp3");
    function showAnswerAndPoints(index: number){
        const audio = new Audio(correctSound)
        audio.play()
        setIsClicked(true)
        showAnswer(index)
    }

    return (
        <>
            {
                isClicked ? 
                <p>{points}</p>
                :
                <button onClick={() => showAnswerAndPoints(index)}>Pokaz odpowiedz</button>
            }
        </>
    )
}